function handleFieldBlur($event: Event) {
  const organisation = document.getElementById('organisationField') as HTMLInputElement;
  const email = document.getElementById('emailField') as HTMLInputElement;
  const firstname = document.getElementById('firstnameField') as HTMLInputElement;
  const lastname = document.getElementById('lastnameField') as HTMLInputElement;

  const isValid = !!organisation.value && !!email.value && !!firstname.value && !!lastname.value;
  const button = document.getElementById('startNowButton') as HTMLButtonElement;
  button.toggleAttribute('disabled', !isValid);
}

function handleStartClick($event: Event) {
  $event.preventDefault();
  const company = document.getElementById('organisationField') as HTMLInputElement;
  const email = document.getElementById('emailField') as HTMLInputElement;
  const firstname = document.getElementById('firstnameField') as HTMLInputElement;
  const lastname = document.getElementById('lastnameField') as HTMLInputElement;

  window.open(`https://mijn.pleqqie.nl/#/starten?companyName=${company.value}&email=${email.value}&firstName=${firstname.value}&lastName=${lastname.value}`, '_blank');
}

function handleLetsStartClick($event: Event) {
  $event.preventDefault();
  const section = document.getElementById('startNow') as HTMLElement;
  console.log(section.offsetTop);
  window.scrollTo({
    top: section.offsetTop,
    left: 0,
    behavior: 'smooth'
  });
}

function bindEventListeners() {
  const startButton: HTMLButtonElement = document.getElementById('startNowButton') as HTMLButtonElement;
  startButton.addEventListener('click', handleStartClick);

  const letsStartButton =  document.getElementById('letsStartButton') as HTMLButtonElement;
  letsStartButton.addEventListener('click', handleLetsStartClick);

  const organisation = document.getElementById('organisationField') as HTMLInputElement;
  organisation.addEventListener('blur', handleFieldBlur);

  const email = document.getElementById('emailField') as HTMLInputElement;
  email.addEventListener('blur', handleFieldBlur);

  const firstname = document.getElementById('firstnameField') as HTMLInputElement;
  firstname.addEventListener('blur', handleFieldBlur);

  const lastname = document.getElementById('lastnameField') as HTMLInputElement;
  lastname.addEventListener('blur', handleFieldBlur);
}

window.addEventListener('load', bindEventListeners);